<template>
	<div class="main-contents prj">		
		<div class="tit">모집중인 프로젝트</div>
		<div class="search-box">
			<!-- <SelectComp type="select" class="wd05" :isAll="true" list="3:공급기업 지정,1:일반 구인" title="프로젝트 구분" v-model="input.projectDivCd"/>						
			<nbsp/> -->
			<SelectComp type="select" class="wd05" :isAll="true" list="4:제안중,2:수행예정,1:수행중" title="프로젝트 상태" v-model="input.projectStatusTemp"/>						
			<nbsp/>
			
			<span v-if="input.projectDivCd == '1'">
				<SelectComp type="select" class="" list="1:프로젝트명,2:프로젝트 담당자명,3:매니저명,4:수행사명" v-model="input.searchGubun"/>						
				<InputComp type="text" :placeholder="input.searchGubun == '1' ? '프로젝트명' : input.searchGubun == '2' ? '프로젝트 담당자명' : input.searchGubun  == '3' ? '매니저명' : '수행사명'" v-model="input.searchKeyword" @keyup.enter="getList('1')"  />
			</span>
			<span v-else>
				<SelectComp type="select" class="" list="1:프로젝트명,2:프로젝트 담당자명,3:매니저명,4:수행사명,5:공급사명" v-model="input.searchGubun"/>						
				<InputComp type="text" :placeholder="input.searchGubun == '1' ? '프로젝트명' : input.searchGubun == '2' ? '프로젝트 담당자명' : input.searchGubun  == '3' ? '매니저명' : input.searchGubun  == '4' ? '수행사명' : '공급사명'" v-model="input.searchKeyword" @keyup.enter="getList('1')"  />
			</span>

			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getList('1')"/>
		</div>

		<!-- 관련내용없는경우 -->
		<div v-if="pageInfo.totalRecordCount == 0" class="view int_prj none">
			<p>프로젝트가 없습니다!</p>
		</div>

		<div v-for="(project, index) in projectList" :key="project.projectSeq">
			<div class="prj_list-box" :ref="project.projectSeq">
				<div class="no">{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}</div>
				<div class="view int_prj">
					<div class="req">
						<div class="top">
							<div v-if="project.projectStatus == 1" class="proc pf">수행중</div>
							<div v-else-if="project.projectStatus == 2" class="proc ep">수행예정</div>
							<div v-else-if="project.projectStatus == 4" class="proc us">제안단계</div>
							<div class="proc rc">+모 집 중</div>
						</div>
						<div class="itv-outline int_prj">
							<div class="regi">
								<div v-if="project.projectDivCd == '31' || project.projectDivCd == '32'" class="tit_sco">공급기업지정</div>
								<div v-else class="tit int_prj">프로젝트명</div>
								<div class="name" style="width: 530px;" @click="clickProjectInfo(project.projectSeq)">{{project.projectNm}}</div>
								<div class="sub-new">
									<div class="label ivl04">고객사</div>
									<div class="list" style="display: inline-flex; width: 540px;">
										<div style="white-space: nowrap;">{{project.clientNm}}</div>
										<div class="loca"><img src="/images/location.png" alt="위치"/>{{project.projectLocCont}}</div>
									</div>
								</div>
								<div class="sub-box">
									<div class="date_new">등록일자 {{project.regYyyymmdd}}</div>
									<div class="input"><img src="/images/admin/input_btn.png " alt="기술인재투입" @click="tecMberPut(project)"/></div>
								</div>
							</div>
							<div class="int_box">
								<div class="list-view int_prj">
									<div class="label ivl04">수행사</div>
										<div class="list">
											<span v-if="input.projectDivCd == '1'">{{project.corpNm}}</span>
											<span v-else>{{project.parentCorpNm}}</span>
									<!-- <div class="loca"><img class="loca" src="/images/location.png" alt="위치"/>{{project.projectLocCont}}</div> -->
										</div>
								</div>
								<div v-if="input.projectDivCd == '3'" class="list-view int_prj">
									<div class="label ivl04">공급사</div><div class="list">{{project.corpNm}}</div>
								</div>
								<div class="list-view int_prj">
									<div class="label ivl04">담당자</div><div class="list">{{project.corpMgrMberNm}}</div>
								</div>
								<div class="list-view int_prj">
									<div class="label ivl05">개발기간</div><div class="list">{{project.projectStartEndYyyymmdd}}</div>
								</div>
								<div class="list-view int_prj">
									<div class="label ivl03">개요</div>
									<div class="list text">{{project.projectDtlDesc}}</div>
								</div>
								<div class="list-view int_prj">
									<div class="label ivl05">개발기술</div>
									<div class="list text">{{project.devTechCont}}</div>
								</div>
								<div class="btn-box notice">
									<div class="btn member">
										<img src="/images/admin/prj_mb_count.png" alt="멤버수"/>
										<div class="number">
											<p>{{project.passMberCnt}}명</p> <!-- 합격 -->
											<p>{{project.mberCntFail}}명</p>  <!-- 불합격 -->
											<p>{{project.mberCntInterview}}명</p>  <!-- 인터뷰 -->
											<p>{{project.inputMberCnt}}명</p>  <!-- 투입 -->
										</div>
									</div>
									<div class="fold_btn">
										<img v-if="project.passMberCnt + project.mberCntFail + project.mberCntInterview + project.inputMberCnt > 0 && projectSeqTecMber != project.projectSeq" src="/images/fold_down_btn.png" alt="버튼" @click="openTecMber(project)"/>
										<img v-if="project.passMberCnt + project.mberCntFail + project.mberCntInterview + project.inputMberCnt > 0 && projectSeqTecMber == project.projectSeq" src="/images/fold_top_btn.png" alt="버튼" @click="closeTecMber()"/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="agent">
						<img src="/images/admin/agent_logo.png" alt="하이프로매니저"/>
						<p>- 하이프로 담당 매니저 -</p>
						<div class="name">
							<span v-if="project.admMgrMberNm" @click="popupAdmMgerMber(project.admMgrMberSeq)">{{project.admMgrMberNm}} &#9654;</span>
							<span v-else>-</span>
						</div>
						<div v-if="project.admLoginId" class="id"><p>id : </p>{{project.admLoginId}}</div>
					</div>
				</div>
			</div>

			<!-- 참여인재 -->
			<div v-if="projectSeqTecMber == project.projectSeq && projectTecMberList.length > 0" class="Board type3 itv show">
				<table class="Board_type3 admin">
					<colgroup>   <!-- 3, 3, 5, 7, 3, 3, 6, 6, 14, 10, 15, 22 -->
						<col width="3%">
						<col width="3%">
						<col width="5%">
						<col width="7%">
						<col width="3%">
						<col width="3%">
						<col width="6%">
						<col width="6%">
						<col width="6%">
						<col width="12%">
						<col width="8%">
						<col width="14%">
						<col width="22%">
					</colgroup>
					<thead>
						<tr>
							<th>선택</th>
							<th>NO</th>
							<th>평균<br/>평가등급</th>
							<th>성명</th>
							<th>성별</th>
							<th>연생</th>
							<th>기술등급<br/>(학력기준)</th>
							<th>기술등급<br/>(자격기준)</th>
							<th>투입등급</th>
							<th>참여소속사</th>
							<th>담당분야</th>
							<th>참여기간</th>
							<th>
								<template v-if="editable(project)">
									<SelectComp v-if="input.projectDivCd == '1'" v-model="intvStatDiv" list="1:인터뷰 요청,2:합격,3:불합격,4:불합격 및 교체" title="기술인재 상태변경" :isAll="true" />
									<SelectComp v-else v-model="intvStatDiv" list="2:합격,3:불합격,4:불합격 및 교체" title="기술인재 상태변경" :isAll="true" />
									<img class="search_btn" src="/images/admin/modify_btn.png" alt="변경버튼" @click="btnModify(project)"/>
								</template>
								<template v-else>기술인재 상태</template>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(mberRow, mberIdx) in projectTecMberList" :key="mberIdx">
							<td class="radio">
								<SelectComp type="radio" v-model="selectedIndex" :list="mberRow.interviewReqSeq + ':'" @change="selectedRow = mberRow"/>
								<!-- <input type="radio" :id="project.projectSeq + '_' + mberIdx" :value="mberIdx" v-model="selectedIndex">
								<label :for="project.projectSeq + '_' + mberIdx">
									<span></span>
								</label> -->
							</td>
							<td class="score">{{mberRow.rn}}</td>
							<td class="score"><gradeImg type="TOT_EVAL_ADM" :grade="mberRow.totEvalGradeCd" :specialYn="mberRow.specialGradeYn"/></td>
							<td class="score name" @click="popupMber(mberRow.mberSeq)">{{mberRow.mberNm}} &#9654;</td>
							<td class="score">{{mberRow.gender | gender}}</td>
							<td class="score">{{mberRow.birthYyyymmdd | birthYear}}</td>
							<td class="score"><gradeImg type="SCHOOL_TECH_ADM" :grade="mberRow.resumeSchoolTechGradeCd"/></td>
							<td class="score"><gradeImg type="LICENSE_TECH_ADM" :grade="mberRow.resumeLicenseTechGradeCd"/></td>
							<td class="score"><gradeImg type="LICENSE_TECH_ADM" :grade="mberRow.projectJoinTechGradeCd"/></td>
							<td class="score">{{mberRow.corpNm}}</td>
							<td class="score">{{mberRow.workCont}}</td>
							<td class="score">{{mberRow.joinStartYyyymmdd | date('yyyy.MM.dd')}} - {{mberRow.joinEndYyyymmdd | date('yyyy.MM.dd')}}</td>
							<td :class="('03,04'.indexOf(mberRow.statusImg) >= 0 && (mberRow.interviewYmd || mberRow.interviewStatusCd == '01')) ? 'rp_input' : 'score'">
								<gradeImg type="INTERVIEW_RES_ADM" :grade="mberRow.statusImg" v-if="mberRow.statusImg"/>
								<p v-if="mberRow.interviewStatusCd == '01'">인터뷰 요청 전입니다.</p>
								<p v-if="'03,04'.indexOf(mberRow.statusImg) > -1 && mberRow.interviewYmd">{{mberRow.interviewYmd | date('yyyy.MM.dd')}} / {{mberRow.interviewAmPm}} {{mberRow.interviewHour}}</p>
							</td>
						</tr>
					</tbody>
				</table>
				<!-- 20230502 테스트 : 선택한 기술인재 이력부분 다운 -->
				<!-- <div class="test-out" style="background-color:red; height:50px; margin-left:80px;">
					<div class="test-in" style="background-color:blue; height:50px; margin:0 auto; width:300px;"></div>
				</div> -->
			</div>
		</div>

		<!-- 페이징부분 -->
		<paging-comp :pageInfo="pageInfo" @page-click="goPage"/>
	</div><!-- //main-contents -->
</template>
<script>
import pagingComp from '@/components/PagingComp.vue';
import gradeImg from "@/components/highpro/GradeImg.vue";

export default {
	components: { pagingComp, gradeImg },
	data() {
		return {
			input : {
				projectDivCd : '',
				searchGubun : '1',		// 1:프로젝트명, 2:담당자명, 3:매니저명, 4:수행사명, 5: 공급사명
				projectStatus : ['0'],	// 0:모집중, 1:수행중, 2:수행예정, 9:완료
				projectStatusTemp : '',
				searchKeyword: '',

				projectStatusAnd : 'Y',	// 프로젝트 상태 조회시 모집중 and (수행중 or 수행예정) 으로 조회하기 위한 구분값
			},
			
			nowYyyymmdd : '',

			selectedIndex : '',
			intvStatDiv : '',
			tecMberSeq : '',
			selectedRow : {},

			projectList : {},
			pageInfo : {},

			projectTecMberList : [],
			projectSeqTecMber : '',

			chgProjectSeq : this.$route.params.chgProjectSeq,
		};
	},

	beforeMount() {
		if(this.$route.name == 'PRJ911M01'){
            this.input.projectDivCd = '1';
        }else{
            this.input.projectDivCd = '3';
        }
	},

	mounted(){

		// console.log('chgProjectSeq', this.chgProjectSeq);

		this.nowYyyymmdd = new Date().format("yyyyMMdd");

		var keys = Object.keys(this.input);
		for(var key in this.$route.params){
			if(keys.indexOf(key) >= 0){
				this.input[key] = this.$route.params[key];
			}
		}

		this.getList();
	},
	methods: {
        getList(div, isScroll = false){

			if(div) this.input.pageIndex = 1;
			
			this.input.projectStatus = ['0'];	// 모집중인 프로젝트 화면으로 모집중 상태는 항상 조회

			if(this.input.projectStatusTemp != '') {
				this.input.projectStatus.push(this.input.projectStatusTemp);
			}

            this.$.httpPost('/api/prj/adm/getProjectList', this.input)
                .then(res => {
					// console.log('res.data', res.data);

                    this.projectList = res.data.list;
                    this.pageInfo = res.data.pageInfo;

					this.projectSeqTecMber = '';

					if(this.chgProjectSeq) {
						for(var i in this.projectList) {
							if(this.projectList[i].projectSeq == this.chgProjectSeq) {
								this.projectList[i].isOpenTecMber = true;
								this.projectSeqTecMber = this.chgProjectSeq
								this.getTecMberList(this.projectSeqTecMber);
							}
						}
					}else if(this.projectList.length > 0 && this.projectList[0].passMberCnt + this.projectList[0].mberCntFail + this.projectList[0].mberCntInterview + this.projectList[0].inputMberCnt  >= 0) {
						// 참여인재 조회
						if(this.$route.params.prjSeq) {
							this.getTecMberList(this.$route.params.prjSeq);
						} else {
							this.projectList[0].isOpenTecMber = true;
							this.projectSeqTecMber = this.projectList[0].projectSeq;
							this.getTecMberList(this.projectSeqTecMber);
						}
						// this.projectList[0].isOpenTecMber = true;
						// this.projectSeqTecMber = this.projectList[0].projectSeq;
						// this.getTecMberList(this.projectSeqTecMber);
						
					}

                    if(isScroll) {
						window.scroll(0, 0);
					}
				})
                .catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
        }, 
		
		getTecMberList(projectSeq) {

			var input = {
				projectSeq : projectSeq
			};

			// console.log(input);

			this.selectedIndex = '';
			this.intvStatDiv = '';
			
			this.$.httpPost('/api/prj/adm/getRecruitProjectTecMberList', input)
				.then(res => {
					// console.log('getRecruitProjectTecMberList', res.data);
					this.projectTecMberList	= res.data.projectTecMberList;

					this.projectSeqTecMber = projectSeq;

					if(this.chgProjectSeq) {
						this.$.focus(this.$refs[this.chgProjectSeq]);
						this.chgProjectSeq = '';
					}
					if(this.$route.params.prjSeq) {
						this.$.focus(this.$refs[this.$route.params.prjSeq]);
					}

				}).catch(this.$.httpErrorCommon);
		},	
		
		openTecMber(project) {
			this.getTecMberList(project.projectSeq);
		},

		closeTecMber() {	
			this.projectSeqTecMber = '';		
			// this.$forceUpdate();
		},		
		
		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList();
		},

		clickProjectInfo(projectSeq) {

			var viewId = 'PRJ911M03'
			if(this.input.projectDivCd == '3') viewId = 'PRJ921M03'

			var params = {
				caller : {
					name: this.$route.name,
					params: this.input
				}
			}

			this.$router.push({name: viewId, query : {projectSeq : projectSeq}, params });

		},	

		popupMber(mberSeq) {
			var param = {};
			param.reqMberSeq = mberSeq;
			param.div = 'tec'; // tec - 개인, gmg - 관리총괄, pmg - 프로젝트 담당자, sco - 공급기업, agt - 매니저

			this.$.popup('/adm/mem/MEM923P02', param);
		},		

		// 프로젝트 담당 매니저 정보 보기
		popupAdmMgerMber(admMgrMberSeq) {
			var param = {};
			param.reqMberSeq = admMgrMberSeq;
			param.div = 'agt'; // tec - 개인, gmg - 관리총괄, pmg - 프로젝트 담당자, sco - 공급기업, agt - 매니저

			this.$.popup('/adm/mem/MEM923P02', param);
		},

		// 참여기간 변경
		changeJoinStartEndYyyymmdd(projectSeq, mberSeq) {
			alert('프로젝트 참여기간 변경 개발 해야 함 projectSeq : ' + projectSeq + ', mberSeq : ' + mberSeq);
		},

		// 평가보기
		viewEval(projectSeq, mberSeq) {
			alert('평가보기 개발 해야 함 projectSeq : ' + projectSeq + ', mberSeq : ' + mberSeq);
		},

		// 평가요청
		reqEval(projectSeq, mberSeq) {
			alert('평가요청 개발 해야 함 projectSeq : ' + projectSeq + ', mberSeq : ' + mberSeq);
		},
		
		// 기술인재 투입 / 교체투입
		// div : 1 - 투입, 2 - 교체투입
		tecMberPut(obj) {
			var param = {};
			param.projectSeq = obj.projectSeq;
			param.div = "1";

			this.$router.push({name:'PRJ911M02', query:param, params:{projectNm:obj.projectNm, div:1}});
		},

		// 기술인재 상태변경
		// intvStatDiv : 1 - 인터뷰 요청, 2 - 합격, 3 - 불합격, 4 - 불합격 및 교체
		btnModify(obj) {
			if(!this.editable(obj)) return alert('총괄관리자 또는 담당매니저만 기술인재 상태변경이 가능합니다.');
			if(!this.intvStatDiv) {
				alert('기술인재 상태변경을 선택하세요.');
				return false;
			}

			if(obj.projectDivCd.substr(0,1) == '3') {
				if(!confirm('해당 프로젝트는 공급기업 지정 프로젝트 입니다.\n계속 진행하시겠습니까?')) {
					return false;
				}
			}
			
			

			// console.log('this.selectedIndex', this.selectedIndex);
			// console.log('this.selectedIndex.length', String(this.selectedIndex).length);
			// console.log('this.selectedRow', this.selectedRow);

			if(Object.keys(this.selectedRow).length == 0) {
				alert('기술인재를 선택하세요.');
				return false;
			}
			
			var param = {};

			if(this.intvStatDiv == '1') {
				param = this.selectedRow
				param.projectSeq = obj.projectSeq;
				param.corpNm = this.selectedRow.corpNm != undefined ? this.selectedRow.corpNm : '소속없음';

				if(param.interviewStatusCd == '04') {
					alert('해당 기술인재는 이미 합격했습니다.');
					return false;
				}

				

				this.$.popup('/adm/prj/PRJ911P01', param)
					.then(res => {
						if(res) this.getTecMberList(obj.projectSeq);
					})
			}else if(this.intvStatDiv == '2') {
				param.tecMberSeq = this.selectedRow.mberSeq;
				param.projectSeq = obj.projectSeq;
				param.interviewReqSeq = this.selectedRow.interviewReqSeq;
				param.corpNm = this.selectedRow.corpNm != undefined ? this.selectedRow.corpNm : '소속없음';

				this.$.popup('/adm/prj/PRJ911P02', param)
					.then(res => {
						if(res) this.getTecMberList(obj.projectSeq); this.getProjectMberCnt(obj.projectSeq);
					})
			}else if(this.intvStatDiv == '3') {
				param.tecMberSeq = this.selectedRow.mberSeq;
				param.projectSeq = obj.projectSeq;
				param.interviewReqSeq = this.selectedRow.interviewReqSeq;
				param.corpNm = this.selectedRow.corpNm != undefined ? this.selectedRow.corpNm : '소속없음';

				this.$.popup('/adm/prj/PRJ911P03', param)
					.then(res => {
						if(res) this.getTecMberList(obj.projectSeq); this.getProjectMberCnt(obj.projectSeq);
					})
			}else if(this.intvStatDiv == '4') {
				param.targetMberSeq = this.selectedRow.mberSeq;
				param.projectSeq = obj.projectSeq;
				param.interviewReqSeq = this.selectedRow.interviewReqSeq;
				param.corpNm = this.selectedRow.corpNm != undefined ? this.selectedRow.corpNm : '소속없음';
				param.div = '2';
				param.type = '1';

				this.$.popup('/adm/prj/PRJ911P04', param);
			}
			
		},

		getProjectMberCnt(seq) {
			this.$.httpPost('/api/prj/adm/getProjectMberCnt', {projectSeq : seq})
				.then(res => {
					// console.log('getProjectMberCnt RESULT', res);

					for(var i in this.projectList) {
						if(this.projectList[i].projectSeq == seq) {
							this.projectList[i].passMberCnt = res.data.passMberCnt;
							this.projectList[i].mberCntFail = res.data.mberCntFail;
							this.projectList[i].mberCntInterview = res.data.mberCntInterview;
							this.projectList[i].inputMberCnt = res.data.inputMberCnt;
						}
					}

				}).catch(this.$.httpErrorCommon);
		},

		editable(project){
			return this.$store.state.userInfo.mberDivCd == '00'				//총괄관리자
				|| this.$store.state.userInfo.mberSeq == project.admMgrMberSeq;//담당매니저
		}
	},
};
</script>
